import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
import NotInterestedIcon from "@material-ui/icons/NotInterested"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    height: "100vh",
    overflow: "auto",

    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
})

const CheckPage = ({ data, location, ...rest }) => {
  let { landings } = data
  let keyword = ""
  const classes = useStyles()
  try {
    landings = landings.edges //node.frontmatter
    landings = landings.map(item => item.node.frontmatter)

    keyword = location.pathname.slice(rest.uri.length)

    if (keyword === "/" || isEmpty(keyword)) {
      throw "keyword isEmpty"
    }
    const result = filter(landings, landing => {
      return landing.keyword === keyword
    })

    if (isEmpty(result)) {
      //keyword can be used
      return (
        <Box className={classes.root}>
          <DoneOutlineIcon style={{ fontSize: 50, color: "green" }} />
          <Box display="flex">you can use that keyword</Box>
        </Box>
      )
    } else {
      //keyword can not be used
      return (
        <Box className={classes.root}>
          <NotInterestedIcon style={{ fontSize: 50, color: "red" }} />
          <Box display="flex">you can not used that keyword</Box>
        </Box>
      )
    }
  } catch {
    return (
      <Box className={classes.root}>
        <CancelPresentationIcon style={{ fontSize: 50, color: "red" }} />
        <Box display="flex">we are unable to check that keyword</Box>
      </Box>
    )
  }
}

CheckPage.propTypes = {
  data: PropTypes.object,
  keyword: PropTypes.string,
  location: PropTypes.object,
}

export const pageQuery = graphql`
  query {
    landings: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "landing" } } }
    ) {
      edges {
        node {
          frontmatter {
            keyword
            lang
          }
        }
      }
    }
  }
`

export default CheckPage
